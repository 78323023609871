module.exports = {
  siteTitle: 'Ilona Wolf | SLP', // <title>
  manifestName: 'Resume',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#009fb2',
  manifestThemeColor: '#0078a8',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/gatsby-starter-resume/`, // This path is subpath of your hosting https://domain/portfolio
  firstName: 'Ilona',
  lastName: 'Wolf',
  // social
  socialLinks: [
    {
      icon: 'fa-linkedin-in',
      name: 'Linkedin',
      url: 'https://www.linkedin.com/in/ilona-wolf-8a07a717b',
    },
  ],
  email: 'IlonaWolf.SLP@gmail.com',
  phone: '',
  address: 'Brooklyn, NY',
};
