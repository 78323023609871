import React from 'react';

import Layout from '../components/Layout';

// import { Link } from 'gatsby';
import Sidebar from '../components/Sidebar';
import config from '../../config';

import obs1 from '../assets/pdf/lesson_plans/obs1.pdf';
import obs2 from '../assets/pdf/lesson_plans/obs2.pdf';
import obs3 from '../assets/pdf/lesson_plans/obs3.pdf';
import obs4 from '../assets/pdf/lesson_plans/obs4.pdf';
import obs5 from '../assets/pdf/lesson_plans/obs5.pdf';
import obs6 from '../assets/pdf/lesson_plans/obs6.pdf';
import obs7 from '../assets/pdf/lesson_plans/obs7.pdf';
import report1 from '../assets/pdf/sample_reports/report1.pdf';
import report2 from '../assets/pdf/sample_reports/report2.pdf';
import report3 from '../assets/pdf/sample_reports/report3.pdf';
import report4 from '../assets/pdf/sample_reports/report4.pdf';

const IndexPage = () => (
  <Layout>
    <Sidebar />
    <div className="container-fluid p-0">
      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="about"
      >
        <div className="w-100">
          <h1 className="mb-0">
            {config.firstName}
            <span className="text-primary"> {config.lastName}</span>
          </h1>
          <div className="subheading mb-5">
            {config.address} · {config.phone} ·
            <a href={`mailto:${config.email}`}>{config.email}</a>
          </div>
          <p className="lead mb-5">
          Master’s degree in Communication Sciences and Disorders with an emphasis on multicultural and multilingual awareness in assessment and treatment. 
          Over fifteen years experience working with children. Motivated to learn and is a team player, searching for a challenging position.
          </p>
          <div className="social-icons">
            {config.socialLinks.map(social => {
              const { icon, url } = social;
              return (
                <a key={url} href={url}>
                  <i className={`fab ${icon}`}></i>
                </a>
              );
            })}
          </div>
        </div>
      </section>

      <hr className="m-0" />

      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="education"
      >
        <div className="w-100">
          <h2 className="mb-5">Education</h2>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <h3 className="mb-0">Long Island University – Brooklyn Campus</h3>
              <div className="subheading mb-3">Masters of Science | Communication Sciences and Disorders</div>
              <p>- Certificate for Teaching Students with Speech-Language Disabilities</p>
              <p>- Bilingual Extension</p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">Sept. 2006 - Aug. 2009</span>
            </div>
          </div>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between">
            <div className="resume-content">
              <h3 className="mb-0">Brooklyn College – Macaulay Honors</h3>
              <div className="subheading mb-3">Bachelor of Arts | Speech Language Pathology/Psychology</div>
              <p></p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">Sept. 2002 – Aug. 2006</span>
            </div>
          </div>
        </div>
      </section>

      <hr className="m-0" />

      <section
        className="resume-section p-3 p-lg-5 d-flex justify-content-center"
        id="experience"
      >
        <div className="w-100">
          <h2 className="mb-5">Experience</h2>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <div className="subheading mb-3">NYC Dept. of Education | The Little Brooklyn Pre-K Center - District 15</div>
              <ul>
                <li>Plan and implement speech and language therapy for children aged 3-5, individually and in groups in a 12:1:1 classroom setting. </li>
                <li>Evaluate children’s progress through informal measures and parent interviews.</li>
                <li>Create Lesson Plans for individualized children as well as Classroom Base Support sessions.</li>
                <li>Conduct classroom based support sessions.</li>
                <li>Conduct remote services through teletherapy platform.</li>
              </ul>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">March 2019-Present</span>
            </div>
          </div>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <div className="subheading mb-3">HeartShare First Step Early Childhood Center</div>
              <ul>
                <li>Plan and implement speech and language therapy for children aged 3-5, individually and in groups in a special education school. </li>
                <li>Evaluate children’s progress through standardized assessments, informal measures and parent interviews.</li>
                <li>Participate in graduate meetings, annual reviews and daily staff meetings to discuss the children and his or her progress.</li>
              </ul>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">Sept. 2009 – March 2019</span>
            </div>
          </div>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <div className="subheading mb-3">Miracle Care (Agency)</div>
              <ul>
                <li>Conduct CPSE services in the home and daycare centers for children aged 3-5 years.</li>
                <li>Evaluate children’s progress through standardized assessments, informal measures, parent interviews.</li>
                <li>Create progress notes and speak with parents during every session to suggest therapeutic techniques to be used in the home.</li>
              </ul>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">Sept. 2012 – March 2019</span>
            </div>
          </div>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <div className="subheading mb-3">Metrotherapy (Agency) </div>
              <ul>
                <li>Conduct Early Intervention/CPSE services both in the home and in daycare settings.</li>
                <li>Conduct Early Intervention/CPSE evaluations for children in the home across all five boroughs.</li>
              </ul>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">Dec. 2011– Dec. 2016</span>
            </div>
          </div>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <div className="subheading mb-3">Bright Start Development (Agency) </div>
              <ul>
                <li>Plan and implement speech and language therapy for children aged 3-5, individually in the home </li>
                <li>Evaluate children’s progress through standardized assessments, informal measures and parent interviews.</li>
              </ul>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">Sept. 2011– June 2013</span>
            </div>
          </div>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between">
            <div className="resume-content">
              <div className="subheading mb-3">The HeartShare School</div>
              <ul>
                <li>Conduct speech and language therapy for children aged 7-15 years in individual sessions.</li>
                <li>Create collaborative sessions with the occupational therapist using sensory activities aimed at minimizing oral motor difficulties.</li>
                <li>Conduct sessions using an augmentative device to increase communication between child and adult.</li>
              </ul>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">July 2010 – Sept. 2010 & Apr. 2011 – Sept. 2011</span>
            </div>
          </div>
        </div>
      </section>

      <hr className="m-0" />

      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="caw"
      >
        <div className="w-100">
          <h2 className="mb-5">Certifications & Workshops</h2>

          <div className="subheading mb-3">
            Certifications
          </div>
          <ul className="fa-ul mb-0">
            <li>
            - Certificate for Teaching Students with Speech-Language Disabilities (w/ Bilingual Extension)            </li>
            <li>
            - ASHA Certified Speech Language Pathologist
            </li>
          </ul>

          {/* <div className="subheading mb-3">Workshops</div>
          <ul className="fa-ul mb-0">
            <li>
              <i className="fa-li fa fa-check"></i>
              Therapy Client Rights Training
            </li> */}
          {/* </ul> */}
        </div>
      </section>

      <hr className="m-0" />

      {/* <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="recommendations"
      >
        <div className="w-100">
          <h2 className="mb-5">Recommendations</h2>
          <p className="mb-0">
            Here is some feedback from my supervisors:
            <ol>
              <li>
                <a href={moses}>Nelson Moses, Ph.D, CCC-SLP</a>
              </li>
              <li>
                <a href={julia}>Julia Agranovich M.S., CCC-SLP</a>
              </li>
              <li>
                <a href={annamarie}>Anna Marie Dorelien, MSEd., BCBA, LBA</a>
              </li>
            </ol>
          </p>
        </div>
      </section>

      <hr className="m-0" />

      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="cpe"
      >
        <div className="w-100">
          <h2 className="mb-5">Clinical Practicum Evaluations</h2>
          <ol>
            <li>
              <a href={eval1}>Evaluation 1</a>
            </li>
            <li>
              <a href={eval2}>Evaluation 2</a>
            </li>
            <li>
              <a href={eval3}>Evaluation 3</a>
            </li>
            <li>
              <a href={eval4}>Evaluation 4</a>
            </li>
          </ol>
        </div>
      </section>

      <hr className="m-0" />

      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="eol"
      >
        <div className="w-100">
          <h2 className="mb-5">Evidence of Learning</h2>
          <p className="mb-0">
            Feel free to take a look at how i've applied what has been taught!
            <ol>
              <li>
                <a href={evidence_of_learning}>Evidence</a>
              </li>
            </ol>
          </p>
        </div>
      </section>

      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="sample_materials"
      >
        <div className="w-100">
          <h2 className="mb-5">Lesson Plans</h2>
          <p className="mb-0">
            Sample lesson plans I have prepared:
            <ol>
              <li>
                <a href={obs1}>Lesson Plan #1</a>
              </li>
              <li>
                <a href={obs2}>Lesson Plan #2</a>
              </li>
              <li>
                <a href={obs3}>Lesson Plan #3</a>
              </li>
              <li>
                <a href={obs4}>Lesson Plan #4</a>
              </li>
              <li>
                <a href={obs5}>Lesson Plan #5</a>
              </li>
              <li>
                <a href={obs6}>Lesson Plan #6</a>
              </li>
              <li>
                <a href={obs7}>Lesson Plan #7</a>
              </li>
            </ol>
          </p>
          <h2 className="mb-5">Reports</h2>
          <p className="mb-0">
            Sample reports I have prepared:
            <ol>
              <li>
                <a href={report1}>Report #1</a>
              </li>
              <li>
                <a href={report2}>Report #2</a>
              </li>
              <li>
                <a href={report3}>Report #3</a>
              </li>
              <li>
                <a href={report4}>Report #4</a>
              </li>
            </ol>
          </p>
        </div>
          </section>*/}
    </div>
  </Layout>
);

export default IndexPage;
